.content-wrapper {
  min-height: 495px;
}

.dashboard-card-line {
  overflow-x: auto;
}

.dashboard-chart-style {
  min-height: 37em !important;
}